import { defineStore } from "pinia"
import type {
  AqeconRoom,
  AqeconEnvironmentMeasurementsResponse,
  AqeconEnvironmentMeasurement, AqeconEnvironmentStats,
  Temperature, Humidity, Co2,
  TemperatureStats, HumidityStats, Co2Stats,
  AqeconEnergyMeasurementsResponse,
  TemperatureResponse, HumidityResponse, Co2Response,
  AqeconEnergyMeasurement, AqeconEnergyStats
} from "~/types/aqeconTypes"

export const useAqeconStore = defineStore('aqeconStore', () => {
  const token = useCookie<string>('token')
  const environmentMeasurements = ref<AqeconEnvironmentMeasurement[]>([])
  const environmentStats = ref<AqeconEnvironmentStats | null>(null)
  const temperatures = ref<Temperature[]>([])
  const temperatureStats = ref<TemperatureStats | null>(null)
  const humidities = ref<Humidity[]>([])
  const humidityStats = ref<HumidityStats | null>(null)
  const co2 = ref<Co2[]>([])
  const co2Stats = ref<Co2Stats | null>(null)
  const energyMeasurements = ref<AqeconEnergyMeasurement[]>([])
  const energyStats = ref<AqeconEnergyStats | null>(null)
  const rooms = ref<AqeconRoom[]>([])

  const getEnvironmentMeasurements = computed(() => environmentMeasurements.value)
  const getEnvironmentStats = computed(() => environmentStats.value)
  const getTemperatures = computed(() => temperatures.value)
  const getTemperatureStats = computed(() => temperatureStats.value)
  const getHumidities = computed(() => humidities.value)
  const getHumidityStats = computed(() => humidityStats.value)
  const getCo2 = computed(() => co2.value)
  const getCo2Stats = computed(() => co2Stats.value)
  const getEnergyMeasurements = computed(() => energyMeasurements.value)
  const getEnergyStats = computed(() => energyStats.value)
  const getRooms = computed(() => rooms.value)

  async function fetchEnvironmentMeasurements(params: { roomId: string | null, fromTime: Date, toTime: Date, deviceId: string }) {
      const response = await $fetch.raw('/api/v1/user/aqecons/environment_measurements', {
        params: {
          deviceId: params.deviceId,
          roomId: params.roomId,
          fromTime: params.fromTime.toISOString(),
          toTime: params.toTime.toISOString()
        },
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      const { stats, data } = response._data as AqeconEnvironmentMeasurementsResponse
      environmentMeasurements.value = data
      environmentStats.value = stats
  }

  async function fetchTemperatures(params: { roomId: string | null, fromTime: Date, toTime: Date, deviceId: string }) {
      const response = await $fetch.raw('/api/v1/user/aqecons/temperatures', {
        params: {
          deviceId: params.deviceId,
          roomId: params.roomId,
          fromTime: params.fromTime.toISOString(),
          toTime: params.toTime.toISOString()
        },
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      const { stats, data } = response._data as TemperatureResponse
      temperatures.value = data
      temperatureStats.value = stats
  }

  async function fetchHumidities(params: { roomId: string | null, fromTime: Date, toTime: Date, deviceId: string }) {
      const response = await $fetch.raw('/api/v1/user/aqecons/humidities', {
        params: {
          deviceId: params.deviceId,
          roomId: params.roomId,
          fromTime: params.fromTime.toISOString(),
          toTime: params.toTime.toISOString()
        },
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      const { stats, data } = response._data as HumidityResponse
      humidities.value = data
      humidityStats.value = stats
  }

  async function fetchCo2(params: { roomId: string | null, fromTime: Date, toTime: Date, deviceId: string }) {
      const response = await $fetch.raw('/api/v1/user/aqecons/co2', {
        params: {
          deviceId: params.deviceId,
          roomId: params.roomId,
          fromTime: params.fromTime.toISOString(),
          toTime: params.toTime.toISOString()
        },
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      const { stats, data } = response._data as Co2Response
      co2.value = data
      co2Stats.value = stats
  }

  async function fetchEnergyMeasurements(params: { roomId: string | null, fromTime: Date, toTime: Date }) {
      const response = await $fetch.raw('/api/v1/user/aqecons/energy_measurements', {
        params: {
          roomId: params.roomId,
          fromTime: params.fromTime.toISOString(),
          toTime: params.toTime.toISOString()
        },
        headers: { 
          Authorization: `Bearer ${token.value}`
        }
      })
      const { stats, data } = response._data as unknown as AqeconEnergyMeasurementsResponse
      energyMeasurements.value = data
      energyStats.value = stats
  }

  async function fetchRooms() {
      const response = await $fetch.raw('/api/v1/user/aqecons/rooms', {
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      const data = response._data as AqeconRoom[]
      rooms.value = data
  }

  return {
    getEnvironmentMeasurements,
    getEnvironmentStats,
    getTemperatures,
    getTemperatureStats,
    getHumidities,
    getHumidityStats,
    getCo2,
    getCo2Stats,
    getEnergyMeasurements,
    getEnergyStats,
    getRooms,
    fetchEnvironmentMeasurements,
    fetchTemperatures,
    fetchHumidities,
    fetchCo2,
    fetchEnergyMeasurements,
    fetchRooms
  }
})